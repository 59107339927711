// @flow
import * as React from 'react';
import { event, select } from 'd3';
import {
    Box,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    InputBase,
    NativeSelect,
    Typography,
    makeStyles,
    Card
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';
import { BarChart, LegendHorizontalDiscrete, SimpleLegend } from '@geostreams/core/src/components/d3';
import Carousel from '@geostreams/core/src/components/Carousel';
import { entries } from '@geostreams/core/src/utils/array';
import { useElementRect } from '@geostreams/core/src/utils/hooks';
import datstorythumbnail from '../../images/datastory.png';
import annualYieldData from '../../data/annual_yield.json';
import annualLoadData from '../../data/annual_load.json';

import huc8_names from '../../data/huc8names.json';
import ar_wq_historical_tn from '../../data/new/ar_wq_historical_tn.json';
import ar_wq_historical_tp from '../../data/new/ar_wq_historical_tp.json';
import ar_huc_trends_tn from '../../data/new/ar_huc_trends_tn.json';
import ar_huc_trends_tp from '../../data/new/ar_huc_trends_tp.json';
import ar_huc_annual_tn from '../../data/new/ar_huc_annual_tn.json';
import ar_huc_annual_tp from '../../data/new/ar_huc_annual_tp.json';
import Details from '../Data-Stories/Details';
import {
    getNutrientValueCategoryIndex,
    FEATURE_STYLE_INFO,
    BOUNDARIES,
    VARIABLES_INFO, HUC8_STYLE_INFO_LEGEND, TRENDS_STYLE_INFO
} from './config';


type Props = {
    regionLabel: string | null;
    featureId: string | null;
    selectedBoundary: string;
    selectedNutrient: string;
    selectedYear: number;
    handleBoundaryChange: Function;
    handleVariableChange: Function;
}
const dataStories = [ {
    title: 'Evaluating Nitrogen and Phosphorus Loads in Mississippi',
    slides: "https://docs.google.com/presentation/d/e/2PACX-1vRPLZUNhABEekztpPbtNtop0r3yRdBJra5zLnayrVsFZVepFfGXztM288LfgNb7BapUF6Ux06TbIEH4/embed?start=true&loop=false&delayms=30000" ,
    thumbnail: datstorythumbnail
},]
const useStyle = makeStyles((theme) => ({
    dropdownsContainer: {
        background: '#e2ebf4'
    },
    header: {
        margin: '10px auto'
    },
    divider: {
        borderTop: '1px dashed #000',
        backgroundColor: 'unset'
    },
    infoIcon: {
        color: '#0D73C5',
        fontSize: '1rem'
    },
    featureProp: {
        color: '#E05769'
    },
    formControl: {
        margin: theme.spacing(1)
    },
    formLabel: {
        padding: theme.spacing(1),
        fontSize: '.88rem'
    },
    selectButton: {
        'background': theme.palette.primary.main,
        'borderRadius': 4,
        'color': theme.palette.primary.contrastText,
        'position': 'relative',
        'height': 42,
        'padding': theme.spacing(2),
        'fontSize': '.75rem',
        '&:focus': {
            borderRadius: 4
        },
        '& option': {
            color: 'initial'
        }
    },
    annualFlowChart: {
        marginTop: -75
    },
    annualFlowLegend: {
        '& svg': {
            fontSize: '.8rem',
            padding: 5,
            border: '1px solid #aaa'
        }
    },
    barChart: {
        '& .xAxis .tick:nth-child(2n) text': {
            visibility: 'hidden'
        }
    },
    annualYieldTooltip: {
        height: 15
    },
    chartTooltip: {
        position: 'fixed',
        background: '#fff',
        border: '1px solid #eee',
        borderRadius: 5,
        padding: 5,
        opacity: 0
    },
    carousel: {
        width: '100%',
        marginBottom: 20
    },
    carouselButton: {
        'backgroundColor': '#0D73C5',
        '&:hover': {
            backgroundColor: '#0D73C5'
        }
    },
    carouselSlideContainer: {
        width: '100%'
    },
    carouselSlide: {
        width: '100%'
    }
}));

const Sidebar = ({
    regionLabel,
    featureId,
    selectedBoundary,
    selectedNutrient,
    selectedYear,
    handleBoundaryChange,
    handleVariableChange
}: Props) => {
    const classes = useStyle();

    const container = React.useRef();
    const containerRect = useElementRect(container);

    const annualHUCChartTooltipRef: { current: null | HTMLDivElement } = React.createRef();
    const annualWaterChartTooltipRef: { current: null | HTMLDivElement } = React.createRef();

    const annualLoadChartData = annualLoadData[featureId];

    const yearsOptions = [];
    let annualYieldChartData;
    let featureValue;
    if (annualYieldData[selectedNutrient][featureId]) {
        featureValue = annualYieldData[selectedNutrient][featureId][selectedYear];
        annualYieldChartData = Object
            .entries(annualYieldData[selectedNutrient][featureId])
            .map(
                ([year, value]) => {
                    // Data is already sorted by year in `src/data/annual_yield.json`
                    yearsOptions.push(<option key={year} value={year}>{year}</option>);
                    return {
                        x: year,
                        y: value,
                        selected: +year === +selectedYear
                    };
                }
            );
    };

    let waterQualityChartData;
    if (selectedNutrient == 'Nitrogen' && ar_wq_historical_tn[featureId]) {
        waterQualityChartData = ar_wq_historical_tn[featureId];
    } else if (selectedNutrient == 'Phosphorus' && ar_wq_historical_tp[featureId]) {
        waterQualityChartData = ar_wq_historical_tp[featureId];
    }

    let hucChartData;
    if (selectedNutrient == 'Nitrogen' && ar_huc_annual_tn[featureId]) {
        hucChartData = ar_huc_annual_tn[featureId];
    } else if (selectedNutrient == 'Phosphorus' && ar_huc_annual_tp[featureId]) {
        hucChartData = ar_huc_annual_tp[featureId];
    }

    let hucTrendData;
    if (selectedNutrient == 'Nitrogen' && ar_huc_trends_tn[featureId]) {
        hucTrendData = ar_huc_trends_tn[featureId];
    } else if (selectedNutrient == 'Phosphorus' && ar_huc_trends_tp[featureId]) {
        hucTrendData = ar_huc_trends_tp[featureId];
    }

    const [iframeProps, updateIframeProps] = React.useState({});

    const [dialogContent, updateDialogContent] = React.useState < null | {
        title: string;
        description: string | React.Node;
    } > (null);
    const handleModalClose = () => updateIframeProps({});
    return (
        <>
            <Box
                className={classes.dropdownsContainer}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <FormControl
                    component="fieldset"
                    className={classes.formControl}
                >
                    <FormLabel
                        component="legend"
                        className={classes.formLabel}
                    >
                        <Box display="flex" alignItems="center">
                            Boundary Type
                            &nbsp;
                            <InfoIcon
                                className={`actionIcon ${classes.infoIcon}`}
                                onClick={(() => updateDialogContent(VARIABLES_INFO.boundary))}
                            />
                        </Box>
                    </FormLabel>
                    <NativeSelect
                        className={classes.selectButton}
                        value={selectedBoundary}
                        onChange={({ target: { value } }) => {
                            handleBoundaryChange(value);
                        }}
                        input={<InputBase />}
                    >
                        {entries(BOUNDARIES).map(([name, { label }]) => (
                            <option
                                key={name}
                                value={name}
                            >
                                {label}
                            </option>
                        ))}
                    </NativeSelect>
                </FormControl>
                <FormControl
                    component="fieldset"
                    className={classes.formControl}
                >
                    <FormLabel
                        component="legend"
                        className={classes.formLabel}
                    >
                        <Box display="flex" alignItems="center">
                            Nutrient
                            &nbsp;
                            <InfoIcon
                                className={`actionIcon ${classes.infoIcon}`}
                                onClick={(() => updateDialogContent(VARIABLES_INFO.nutrient))}
                            />
                        </Box>
                    </FormLabel>
                    <NativeSelect
                        className={classes.selectButton}
                        value={selectedNutrient}
                        onChange={({ target: { value } }) => {
                            handleVariableChange(value, 'nutrient');
                        }}
                        input={<InputBase />}
                    >
                        <option value="Phosphorus">Phosphorus</option>
                        <option value="Nitrogen">Nitrogen</option>
                    </NativeSelect>

                </FormControl>
                <FormControl
                    component="fieldset"
                    className={classes.formControl}
                    disabled={!yearsOptions.length}
                >
                    <FormLabel
                        component="legend"
                        className={classes.formLabel}
                    >
                        Year {!yearsOptions.length ? '(N/A)' : ''}
                    </FormLabel>
                    <NativeSelect
                        className={classes.selectButton}
                        value={selectedYear}
                        onChange={({ target: { value } }) => {
                            handleVariableChange(value, 'year');
                        }}
                        input={<InputBase />}
                    >
                        {yearsOptions}
                    </NativeSelect>
                </FormControl>
            </Box>

            <Container ref={container}>
                <Typography
                    className={classes.header}
                    variant="h5"
                >
                    {regionLabel} - <span className={classes.featureProp}>{featureId}</span>
                </Typography>

                {selectedBoundary === 'watershed' && annualLoadChartData ?
                    <>
                        <Divider className={classes.divider} />
                        <Box className={classes.annualFlowLegend} display="flex" justifyContent="space-between">
                            <Typography variant="subtitle1">
                                ANNUAL NITRATE LOAD
                            </Typography>
                            <SimpleLegend
                                width={165}
                                itemHeight={13}
                                marginBottom={4}
                                data={[
                                    { label: 'Annual Load', type: 'polygon', color: '#117fc9', width: 2, opacity: 1 },
                                    { label: 'Flow Normalized Load', type: 'line', color: '#f63700', width: 2, opacity: 1 },
                                    { label: '95% Confidence Interval', type: 'polygon', color: '#fdb47f', width: 2, opacity: 1 }
                                ]}
                            />
                        </Box>
                        <BarChart
                            className={classes.barChart}
                            barsData={
                                annualLoadChartData.annual_load.map(
                                    ({ x, y }) => ({
                                        x,
                                        y,
                                        selected: x === +selectedYear
                                    })
                                )
                            }
                            lineData={annualLoadChartData.normalized_flow}
                            intervalData={annualLoadChartData.confidence_interval}
                            xAxisProps={{
                                title: 'Year',
                                titlePadding: 50,
                                stroke: '#4682b4',
                                strokeWidth: 2
                            }}
                            yAxisProps={{
                                title: 'Tons',
                                titlePadding: 40,
                                stroke: '#4682b4',
                                strokeWidth: 2
                            }}
                            barStroke={(d) => yearsOptions.length && d.selected ? 'red' : '#117fc9'}
                            barStrokeWidth={2}
                            barStrokeOpacity={(d) => d.selected ? 1 : 0}
                            barFill="#117fc9"
                            barFillOpacity="1"
                            lineStroke="#f63700"
                            lineStrokeWidth={2}
                            intervalFill="#fdb47f"
                            width={(window.innerWidth / 3) - 50}
                            height={300}
                            marginTop={50}
                            marginBottom={60}
                            marginLeft={60}
                            marginRight={20}
                        />
                    </> :
                    null}

                {featureValue !== undefined ?
                    <>
                    </> :
                    null}

                {selectedBoundary === 'drainage' ?
                    <Typography variant="subtitle2" align="center" gutterBottom>
                        <Container>
                            <LegendHorizontalDiscrete
                                boxCount={3}
                                boxWidth={((window.innerWidth / 3) - 150) / 3}
                                getBoxInfo={(idx) => TRENDS_STYLE_INFO[idx]}
                            />
                        </Container>

                        <p>Only quality-assured sites in Tier 1 watersheds are shown.</p>

                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.agriculture.arkansas.gov/natural-resources/divisions/water-management/arkansas-nutrient-reduction-strategy/"
                        >

                            Arkansas Nutrient Reduction Strategy 2022
                        </a>
                    </Typography> : null}

                {selectedBoundary === 'huc8' && featureId !== undefined ?
                    <Typography variant="subtitle2" align="center" gutterBottom>
                        <p> {huc8_names[featureId] ? huc8_names[featureId].Name : 'Select a HUC'}</p>

                        {hucChartData ? <BarChart
                            className={classes.barChart}
                            barsData={
                                hucChartData.mean_obs.map((entry) => ({
                                    x: entry.x,
                                    y: entry.y
                                }))
                            }
                            xAxisProps={{
                                title: 'Year',
                                titlePadding: 50,
                                stroke: '#4682b4',
                                strokeWidth: 2
                            }}
                            yAxisProps={{
                                title: 'Mean',
                                titlePadding: 40,
                                stroke: '#4682b4',
                                strokeWidth: 2
                            }}
                            mouseOver={(d) => {
                                select(annualHUCChartTooltipRef.current)
                                    .html(`${d.x} - ${d.y}`)
                                    .transition()
                                    .duration(200)
                                    .style('opacity', .9)
                                    .style('left', `${event.clientX}px`)
                                    .style('top', `${event.clientY - 50}px`);
                            }}
                            mouseOut={() => {
                                select(annualHUCChartTooltipRef.current)
                                    .transition()
                                    .duration(500)
                                    .style('opacity', 0);
                            }}
                            barStroke={(d) => yearsOptions.length && d.selected ? 'red' : '#117fc9'}
                            barStrokeWidth={2}
                            barStrokeOpacity={(d) => d.selected ? 1 : 0}
                            barFill="#117fc9"
                            barFillOpacity="1"
                            lineStroke="#f63700"
                            lineStrokeWidth={2}
                            intervalFill="#fdb47f"
                            width={(containerRect.width || 0) * 0.9}
                            height={300}
                            marginTop={50}
                            marginBottom={60}
                            marginLeft={60}
                            marginRight={20}
                        /> : null}
                        <div ref={annualHUCChartTooltipRef} className={classes.chartTooltip} />

                        <Divider className={classes.divider} />
                        <Typography
                            className={classes.header}
                            variant="subtitle1"
                            gutterBottom
                        >
                            <span className={classes.featureProp}>Aggregated HUC8 Trend Analysis</span>
                        </Typography>

                        <Container>
                            <LegendHorizontalDiscrete
                                boxCount={4}
                                boxWidth={((window.innerWidth / 3) - 150) / 4}
                                getBoxInfo={(idx) => HUC8_STYLE_INFO_LEGEND[idx]}
                            />
                        </Container>
                    </Typography> : null}

                {selectedBoundary === 'waterquality' && featureId !== undefined ?
                    <Typography variant="subtitle2" align="center" gutterBottom>
                        <p> Concentration</p>
                        <p> {featureId}</p>

                        {waterQualityChartData ? <BarChart
                            className={classes.barChart}
                            barsData={
                                waterQualityChartData.map((entry) => ({
                                    x: entry.x.split(' ')[0],
                                    y: entry.y
                                }))
                            }
                            xAxisProps={{
                                title: 'Date',
                                titlePadding: 50,
                                stroke: '#4682b4',
                                strokeWidth: 2,
                                ticks: [20]
                            }}
                            yAxisProps={{
                                title: 'Total (mg/L)',
                                titlePadding: 40,
                                stroke: '#4682b4',
                                strokeWidth: 2
                            }}
                            mouseOver={(d) => {
                                select(annualWaterChartTooltipRef.current)
                                    .html(`${d.x} - ${d.y} mg/L`)
                                    .transition()
                                    .duration(200)
                                    .style('opacity', .9)
                                    .style('left', `${event.clientX}px`)
                                    .style('top', `${event.clientY - 50 }px`);
                            }}
                            mouseOut={() => {
                                select(annualWaterChartTooltipRef.current)
                                    .transition()
                                    .duration(500)
                                    .style('opacity', 0);
                            }}
                            barStroke={(d) => yearsOptions.length && d.selected ? 'red' : '#117fc9'}
                            barStrokeWidth={2}
                            barStrokeOpacity={(d) => d.selected ? 1 : 0}
                            barFill="#117fc9"
                            barFillOpacity="1"
                            lineStroke="#f63700"
                            lineStrokeWidth={2}
                            intervalFill="#fdb47f"
                            width={(containerRect.width || 0) * 0.9}
                            height={300}
                            marginTop={50}
                            marginBottom={60}
                            marginLeft={60}
                            marginRight={20}
                        /> : null}
                        <div ref={annualWaterChartTooltipRef} className={classes.chartTooltip} />

                        <p>{waterQualityChartData ? waterQualityChartData.length : 0} observations</p>
                    </Typography> : null}

                {selectedBoundary === 'waterquality' ?
                    <div>
                        <b>DATA AVAILABILITY</b>
                        <br></br>
                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="2" fill="white" stroke="black" />
                        </svg> Less than 2 years
                        <br></br>
                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="4" fill="#93BDD4" stroke="black" />
                        </svg> 2-5 years
                        <br></br>
                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="6" fill="#4D94C1" stroke="black" />
                        </svg> 5-10 years
                        <br></br>
                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="8" fill="#1B64A7" stroke="black" />
                        </svg> 10-20 years
                        <br></br>
                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="10" fill="#062D64" stroke="black" />
                        </svg> 20+ years
                    </div> : null
                }
            </Container>

            {dialogContent ?
                <Dialog open onClose={() => updateDialogContent(null)}>
                    <DialogTitle disableTypography>
                        <Typography variant="h6" align="center">
                            {dialogContent.title}
                            <IconButton
                                className="right noPadding actionIcon"
                                onClick={() => updateDialogContent(null)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        {dialogContent.description}
                    </DialogContent>
                </Dialog> :
                null}
              <>
         <Details {...iframeProps} handleClose={handleModalClose} />
        <Container id="toc">
            <Typography
                className={classes.header}
                variant="h6"
                noWrap
                gutterBottom
            >
                View Data Story
            </Typography>
            <Divider />
            <Container className={classes.carousel}>
                <Divider />
               
               {dataStories.map(({ title, thumbnail, slides }) => (
                        <Grid
                            key={title}
                            className={`actionIcon ${classes.carouselSlideContainer}`}
                            onClick={() => updateIframeProps({
                                source: slides,
                                title
                            })}
                        >
                            <Grid
                                item
                                xs={12}
                                component="img"
                                src={thumbnail}
                                title={title}
                                className={classes.carouselSlide}
                            />
                            <Grid item xs={12}>
                                <Typography
                                    gutterBottom
                                    variant="subtitle2"
                                    component="h2"
                                >
                                    {title}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
             
               
                    
            </Container>
          
        </Container>
        </>
        </>
    );
};

export default Sidebar;
